import axios from 'axios';

export default async function callAPIs(system_prompt, user_prompt, setResponse, setLoading){
   
    const requestObject = {
        system_prompt: system_prompt,
        user_prompt: user_prompt,
    }
    axios.post(process.env.REACT_APP_BACKEND_API_URL, requestObject).then( (response) => {
        console.log(response);
        setResponse(response.data);
        setLoading(false);
    }).catch( (error) =>  {
        console.log(error);
    }).then(()=>{});
}